<template>
	<v-container class="py-8">
		<span class="component-name">{{ $options.name }}</span>
		<div class="mb-4 action-summary-area" :class="[
				$vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : '',
				this.completeBatchProcess ? 'summary-visible' : '',]">
			<div class="d-flex justify-space-between mb-4">
				<div class="d-flex flex-grow-1">
					<v-text-field prepend-inner-icon="mdi-magnify" :placeholder="$t('document_list.search_field_text')" solo
						clearable flat outlined dense hide-details v-model="searchKeyWord"></v-text-field>
					<v-btn class="ml-2 primary" height="40" dark @click="loadSearchList()">
						{{ $t("document_list.search") }}
					</v-btn>
				</div>
				<v-btn class="ml-6 secondary" fab small dark depressed @click="showAdvanceSearchDialog()">
					<span class="filter-count primary" v-if="searchFilterCount > 0">
						{{ searchFilterCount }}</span>
					<v-icon>mdi-tune</v-icon>
				</v-btn>
			</div>

			<div v-if="this.completeBatchProcess || rejectedDocCount > 0"
				class="mb-2 final-doc-count font-weight-bold py-3 px-4">
				<div v-if="this.completeBatchProcess">
					{{ $t("document_list.signed_documents") }}:
					<span class="ml-1">{{ successDocs }}</span>
				</div>
				<div v-if="rejectedDocCount > 0">
					{{ $t("document_list.rejected_documents") }}:
					<span class="ml-1">{{ rejectedDocCount }}</span>
				</div>
			</div>

			<div class="mb-4 pending-doc-count font-weight-bold py-3 px-4">
				<div>
					{{ $t("document_list.documents_pending_signature") }}:
					<span class="ml-1">{{ numReqPending }}</span>
				</div>
				<div v-if="this.docList.length > 0">
					{{ $t("document_list.documents_selected_for_multiple_signature") }}:
					<span class="ml-1">{{ selectedDocs.length }}</span>
				</div>
			</div>
		</div>
		<v-card outlined class="document-list-card-wrapper">
			<v-data-table v-model="selectedDocs" class="document-list" :headers="headers" :items="docList" item-key="id"
				show-select :hide-default-footer="false" :mobile-breakpoint="0" item-class="status" :items-per-page="10"
				@toggle-select-all="checkSelectAll" :footer-props="{
					'items-per-page-options': [10],
				}">
				<template v-slot:[`item.signatureTransaction.description`]="{ item }">
					<router-link
						:to="`/${hasAssociation ? (currentRoute.params.association + '/') : ''}document/` + item.id"
						class="document-link">
						{{
							item.signatureTransaction.description
							? item.signatureTransaction.description
							: "-"
						}}
					</router-link>
				</template>
				<template v-slot:[`item.dateSent`]="{ item }">
					{{
						item.dateSent && item.dateSent !== null && item.dateSent != "null"
						? moment(item.dateSent).format("YYYY-MM-DD")
						: "-"
					}}
				</template>
				<template v-slot:[`item.signatureTransaction.validityDate`]="{ item }">
					{{
						item.signatureTransaction.validityDate &&
						item.signatureTransaction.validityDate != null &&
						item.signatureTransaction.validityDate != "null"
						? moment(item.signatureTransaction.validityDate).format(
							"YYYY-MM-DD"
						)
						: "-"
					}}
				</template>
			</v-data-table>
		</v-card>

		<div class="text-center pt-4 document-list-pagination-wrapper"></div>

		<div class="footer-message">
			<v-container class="px-2 fill-height">
				<v-row>
					<div class="col-md-8 col-xs-12 text-left terms">
						<v-list>
							<v-list-item>
								<template v-slot:default="{ active }">
									<v-list-item-action>
										<v-checkbox dark color="#F36E21" v-model="terms_accepted" :input-value="active"
											:disabled="selectedDocs.length < 1"></v-checkbox>
									</v-list-item-action>

									<v-list-item-content class="white--text">
										{{ $t("document_list.read_and_understand_the_documents") }}
									</v-list-item-content>
								</template>
							</v-list-item>
						</v-list>
					</div>
					<div class="row">
						<div class="col-md-6 col-6 sign pl-0">
							<v-btn large color="#47D764" :disabled="!this.terms_accepted" block dark
								class="sign-reject-btn" :loading="loading" @click="signDocument()">
								<v-icon class="mr-2">mdi-pencil-box-outline</v-icon>
								{{ $t("sign") }}
							</v-btn>
						</div>
						<div class="col-md-6 col-6 reject pr-0">
							<v-btn large color="#f36e21" class="sign-reject-btn w-100" block dark @click="review()"
								:disabled="docList.length < 1">
								{{ $t("document_list.review_and_select") }}
							</v-btn>
						</div>
					</div>
				</v-row>
			</v-container>
		</div>

		<doc-list-advance-search :dialog="advanceSearchDialog" :errorMsg="errorMsg" :error="error"
			@submitFilters="submitFilters"></doc-list-advance-search>

		<sign-confirm-pad-modal :dialog="padDialog" :errorMsg="errorMsg" :error="error"
			@submitSignPad="submitSignPad"></sign-confirm-pad-modal>

		<certificate-pin-modal :dialog="certificatePinCodeDialog" :errorMsg="errorMsg" :error="error"
			@submitCertificatePin="submitCertificatePin"></certificate-pin-modal>
		
		<sign-confirm-otp-modal :dialog="otpDialog" :errorMsg="errorMsg" :error="error" @submitOtpCode="submitOtpCode"
			@resendOtpCode="sendOtpCode" />

		<DefaultLoading v-if="loading" :loading="loading" />
	</v-container>
</template>

<script>
import DocListAdvanceSearch from "../components/shared/DocListAdvanceSearch.component";
import SignConfirmPadModal from "../components/shared/SignConfirmPadModal.component";
import SignConfirmOtpModal from "../components/shared/SignConfirmOtpModal.component";
import CertificatePinModal from "../components/shared/CertificatePinModal.component.vue";
import DefaultLoading from "../components/shared/DefaultLoading.component";
import { SignaturesService } from "../services/signatures-service";
import moment from "moment";

export default {
	name: "document-list",
	components: {
		DocListAdvanceSearch,
		SignConfirmPadModal,
		SignConfirmOtpModal,
		CertificatePinModal,
		DefaultLoading,
	},
	data: function () {
		return {
			otpDialog: false,
			otpCode: "",

			loading: false,
			padDialog: false,
			certificatePinCodeDialog: false,
			certificatePinCode: null,
			signaturePadImage: null,
			errorMsg: null,
			error: null,
			terms_accepted: "",
			searchFilters: {},
			selectedDocs: [],
			advanceSearchDialog: false,
			totalSelectedDocs: 0,
			successDocs: 0,
			failDocs: 0,
			invalidPin: false,
			page: 1,
			totalPages: 0,
			itemsPerPage: 10,
			docList: [],
			pageNumber: 1,
			numReqPending: 0,
			searchKeyWord: null,
			completeBatchProcess: false,
			prevRoute: "-",

			pendingStatus: 20,
			searchFilterCount: 0,
			padStatus: false,
			rejectedDocCount: 0,
			searchUrlParams: {},
		};
	},
	computed: {
		headers() {
			return [
				{
					text: this.$t("document_list.document"),
					value: "signatureTransaction.description",
					sortable: false,
				},
				{
					text: this.$t("document_list.send"),
					value: "dateSent",
					sortable: false,
				},
				{
					text: this.$t("document_list.expiration"),
					value: "signatureTransaction.validityDate",
				},
			];
		},
		prevRoutePath() {
			return this.prevRoute ? this.prevRoute.path : "/";
		},
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		},
		currentRoute() {
			return this.$route;
		}
	},
	watch: {
		successDocs() {
			if (this.successDocs > 0) {
				this.checkBatchProcessStatus();
			}
		},
		failDocs() {
			if (this.failDocs > 0) {
				this.checkBatchProcessStatus();
			}
		},
	},
	methods: {
		resetProcess() {
			this.otpDialog = false;
			this.otpCode = "";
			this.padDialog = false;
			this.certificatePinCodeDialog = false;
			this.certificatePinCode = null;
			this.signaturePadImage = null;
			this.errorMsg = null;
			this.error = null;
			this.terms_accepted = "";
			this.searchFilters = {};
			this.selectedDocs = [];
			this.advanceSearchDialog = false;
			this.totalSelectedDocs = 0;
			this.invalidPin = false;
			this.docList = [];
			this.totalPages = 0;
			this.pageNumber = 1;
			this.numReqPending = 0;
			this.searchKeyWord = "";
			this.padStatus = false;
			localStorage.setItem("reviewed_list", "");
		},
		checkBatchProcessStatus() {
			if (
				this.totalSelectedDocs == this.successDocs + this.failDocs &&
				!this.invalidPin
			) {
				this.page = 1;
				this.resetProcess();
				this.loadDocumentList({});
				this.certificatePinCodeDialog = false;
				this.completeBatchProcess = true;
				this.resetSuccessCount();
			}
		},
		showAdvanceSearchDialog() {
			this.advanceSearchDialog = true;
		},
		submitFilters(filters) {
			this.searchKeyWord = "";
			this.docList = [];
			this.searchFilters = JSON.parse(JSON.stringify(filters));
			this.page = 1;
			this.loadDocumentList(JSON.parse(JSON.stringify(filters)));
			this.advanceSearchDialog = false;
			localStorage.setItem("reviewed_list", "");
		},
		signDocument() {
			console.log("signDocument");
			if (this.selectedDocs.find((o) => o.pad === true)) {
				console.log("pad true");
				this.padStatus = true;
				this.padDialog = true;
			} else if(this.selectedDocs.find((o) => o.type === "ONETIMEREGISTERED")){

				console.log("type registered");
				this.lastRefresh = moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ");
				this.location = this.userLatitude && this.userLongitude
						? this.userLatitude + ", " + this.userLongitude
						: "",
				this.submitBatchOfDocuments();
			} else {
				console.log("else");
				this.certificatePinCodeDialog = true;
				this.padStatus = false;
			}
		},
		submitSignPad(signature) {
			console.log("submitSignPad");
			this.signaturePadImage = signature;
			this.padDialog = false;
			if(this.selectedDocs.find((o) => o.type === "ONETIMEREGISTERED" && o.otp === false)){
				console.log("onetimeregistered");
				this.lastRefresh = moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ");
				this.location = this.userLatitude && this.userLongitude
						? this.userLatitude + ", " + this.userLongitude
						: "",
				this.submitBatchOfDocuments();
			} if(this.selectedDocs.find((o) => o.type === "ONETIMEREGISTERED" && o.otp === true)){
				console.log("otp y onetimeregistered");
				this.lastRefresh = moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ");
				this.location = this.userLatitude && this.userLongitude
						? this.userLatitude + ", " + this.userLongitude
						: "",
				this.submitBatchOfDocuments();
				this.otpDialog = true;
			} else {
				this.certificatePinCodeDialog = true;
				this.padStatus = false;
				}
		},
		submitOtpCode(otpCode){
			this.otpCode = otpCode;
			if (this.otpStatus) {
				this.otpDialog = true;
			}
		},
		submitCertificatePin(pinCode) {
			this.certificatePinCode = pinCode;
			this.submitBatchOfDocuments();
		},
		async submitBatchOfDocuments() {
			if (this.selectedDocs.length > 0) {
				this.totalSelectedDocs = this.selectedDocs.length;
				this.invalidPin = false;
				this.error = false;
				this.loading = true;
				this.successDocs = 0;
				this.failDocs = 0;
				const apiToken = localStorage.getItem("auth_user_token");

				await this.signCertificateSignature(apiToken, this.selectedDocs[0]);
				if (!this.invalidPin) {
					this.selectedDocs.shift();
					this.selectedDocs.forEach(async (list) => {
						await this.signCertificateSignature(apiToken, list);
					});
				}
			}
		},
		async signCertificateSignature(apiToken, list) {
			let data = { comments: "", password: this.certificatePinCode };
			if (list.pad) {
				data["signatureImage"] = this.signaturePadImage;
			}
			if (this.lastRefresh) {
				data["lastRefresh"] = this.lastRefresh;
	
			}
			if (this.location) {
				data["location"] = this.location;
			}
			let signatureId = list.id;
			await SignaturesService.signCertificateSignaturesDocument(
				apiToken,
				data,
				signatureId
			)
				.then((response) => {
					if (response.status == 200) {
						this.successDocs++;
					}
				})
				.catch((error) => {
					this.failDocs++;
					if (error.response.status == 422) {
						this.invalidPin = true;
						this.error = true;
						this.errorMsg =
							error.response.data.description == "SYSTEM.PKCS11_PIN_INCORRECT"
								? this.$t("document_list.invalid_pin_code")
								: this.$t("server_error");
						this.loading = false;
					}
				});
		},
		review() {
			this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}multiple-document-list-review` })
		},
		setParams(params) {
			this.searchFilterCount = Object.keys(this.searchFilters).filter(
				(x) => this.searchFilters[x] != undefined
			).length;
			if (this.searchFilterCount > 0) {
				Array.prototype.push.apply(params, this.searchFilters);
			}
			if (this.searchKeyWord) {
				params["description"] = this.searchKeyWord;
			}
			if ( //Is redirect from another page
				this.prevRoute.name &&
				this.prevRoute.name == "document-list-review"
			) {
				this.page = 1;
				this.prevRoute = "";
			}
			params["page"] = this.page;
			params["status"] = this.pendingStatus;
			return params;
		},
		resetRejectCount() {
			setTimeout(
				function () {
					this.rejectedDocCount = 0;
					localStorage.setItem("rejected_doc_count", this.rejectedDocCount);
				}.bind(this),
				4000
			);
		},
		resetSuccessCount() {
			setTimeout(
				function () {
					this.completeBatchProcess = false;
					this.successDocs = 0;
					this.failDocs = 0;
				}.bind(this),
				4000
			);
		},
		loadDocumentList(paramsList) {
			this.loading = true;
			let params = this.setParams(paramsList);
			const apiToken = localStorage.getItem("auth_user_token");

			SignaturesService.getCertificateSignatureRequests(apiToken, params)
				.then((response) => {
					this.docList = this.docList.concat(
						response.data.signatureRequestsList
					);
					this.setReviewLocalData(params);
					this.page = response.data.pageNumber;
					this.totalPages = response.data.totalPages;
					this.numReqPending = response.data.numReqPending;

					this.loading = false;
					this.loadAllPagination();
				})
				.catch(() => {
					this.loading = false;
				});
		},
		loadAllPagination() {
			if (this.totalPages > this.page) {
				const reviewParams = this.getReviewUrlParams();
				this.page = this.page + 1;
				this.loadDocumentList(reviewParams);
			} else {
				const reviewedList = this.checkReviewedDocumentList();
				if (reviewedList) {
					this.selectedDocs = reviewedList;
				} else {
					this.selectedDocs = this.docList;
				}
				this.rejectedDocCount = Number(this.getRejectedCount());
				this.resetRejectCount();
			}
		},
		getReviewUrlParams() {
			return JSON.parse(JSON.stringify(this.searchUrlParams));
		},
		checkReviewedDocumentList() {
			const reviewed =
				localStorage.getItem("reviewed_list") != ""
					? JSON.parse(localStorage.getItem("reviewed_list"))
					: "";
			return reviewed.length > 0 ? reviewed : "";
		},
		setReviewLocalData(params) {
			this.searchUrlParams = params;
		},
		loadSearchList() {
			this.searchFilters = {};
			this.docList = [];
			this.page = 1;
			this.loadDocumentList({});
			localStorage.setItem("reviewed_list", "");
		},
		getRejectedCount() {
			return localStorage.getItem("rejected_doc_count");
		},
		setRejectedCount(value) {
			return localStorage.setItem("rejected_doc_count", value);
		},
		checkSelectAll(x) {
			if (x.value) {
				this.selectedDocs = this.docList;
			} else {
				this.selectedDocs = [];
			}
		},
	},
	mounted() {
		this.loadDocumentList({});
		this.moment = moment;
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.prevRoute = from;
		});
	},
};
</script>
